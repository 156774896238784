import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';

import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

interface ConfirmationDialogDialogProps {
  closeButtonLabel: string;
  closeConfirmDialog: () => void;
  confirmButtonLabel: string;
  description: string;
  title: string;
  handleClose: () => void;
  handleConfirm: () => void;
  isOpen?: boolean;
}

const DIALOG_TITLE = 'Confirmation Dialog';
const CLOSE_BUTTON_LABEL = 'Close';
const CONFIRM_BUTTON_LABEL = 'Confirm';

const ConfirmationDialog = (props: ConfirmationDialogDialogProps): React.JSX.Element => {
  const {
    closeButtonLabel = CLOSE_BUTTON_LABEL,
    closeConfirmDialog = () => void {},
    confirmButtonLabel = CONFIRM_BUTTON_LABEL,
    description = '',
    title = DIALOG_TITLE,
    handleClose = () => void {},
    handleConfirm = () => void {},
    isOpen = false,
  } = props;

  return (
    <Modal isOpen={isOpen} onClose={closeConfirmDialog} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{description}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            data-testid={ELEMENT_DATA_TEST_IDS.CONFIRMATION_DIALOG_CANCEL_BUTTON}
            colorScheme="red"
            variant="ghost"
            mr={3}
            onClick={handleClose}
          >
            {closeButtonLabel}
          </Button>
          <Button
            data-testid={ELEMENT_DATA_TEST_IDS.CONFIRMATION_DIALOG_CONFIRM_BUTTON}
            colorScheme={'blue'}
            onClick={handleConfirm}
          >
            {confirmButtonLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationDialog;
