/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import { Tab, TabIndicator, TabList, Tabs, VStack } from '@chakra-ui/react';
import React from 'react';

import { useGetAssignmentsQuery } from '@/API/assignments.api';
import { useGetTalliesQuery } from '@/API/tallies.api';
import ViewEditorAssignments from '@/components/view-editor/ViewEditorAssignments';
import ViewEditorDemandTallies from '@/components/view-editor/ViewEditorDemandTallies';
import ViewEditorTallies from '@/components/view-editor/ViewEditorTallies';
import { useAppSelector } from '@/store/hooks';
import Assignment, { AssignmentStructure } from '@/types/assignment.types';

enum ViewEditorAssignmentsTalliesTabs {
  ASSIGNMENTS,
  TALLIES,
  DEMAND,
  MAXTABS,
}

const selectedTabStyle = {
  color: 'white',
};

const getAssignmentStructures = (assignment: Assignment, selectedTemplateIds: number[]): AssignmentStructure[] => {
  const structures: AssignmentStructure[] = [];

  assignment.structures.forEach((structure) => {
    // If one template is selected and a structure matches then add it
    // If multiple templates are selected and a structure matches,
    // if the structure is not mapped, add it
    // if the structure is mapped, AND is the parent, add it
    // eslint-disable-next-line no-magic-numbers
    if (selectedTemplateIds.length === 1 && structure.template.id === selectedTemplateIds[0]) {
      const assignmentStructure = {
        active: structure.active,
        displayName: `${structure.display_name} (${structure.template.display_name})`,
        endDate: structure.end_date,
        expired: structure.expired,
        id: structure.id,
        isMapped: structure.is_mapped,
        isParent: structure.is_parent,
        name: `${structure.display_name} (${structure.template.display_name})`,
        startDate: structure.start_date,
        templateId: structure.template.id,
        templateName: structure.template.display_name,
        typeId: assignment.aTypeId,
      };

      structures.push(assignmentStructure);
    } else {
      if (selectedTemplateIds.includes(structure.template.id)) {
        if (!structure.is_mapped || structure.is_parent) {
          const assignmentStructure = {
            active: structure.active,
            displayName: `${structure.display_name} (${structure.template.display_name})`,
            endDate: structure.end_date,
            expired: structure.expired,
            id: structure.id,
            isMapped: structure.is_mapped,
            isParent: structure.is_parent,
            name: `${structure.display_name} (${structure.template.display_name})`,
            startDate: structure.start_date,
            templateId: structure.template.id,
            templateName: structure.template.display_name,
            typeId: assignment.aTypeId,
          };

          structures.push(assignmentStructure);
        }
      }
    }
  });

  return structures;
};

const ViewEditorAssignmentsTallies = (): React.JSX.Element => {
  const { viewDraft: view } = useAppSelector((state) => state.viewEditor);

  const [selectedTabIndex, setSelectedTabIndex] = React.useState<ViewEditorAssignmentsTalliesTabs>(
    ViewEditorAssignmentsTalliesTabs.ASSIGNMENTS,
  );

  // Fetch assignments for the selected departments and templates
  const { data: assignmentsData, isLoading } = useGetAssignmentsQuery(
    {
      departmentList: view.filter.on_departments,
      templateList: view.filter.on_templates,
    },
    // ToDo: This seems wrong
    { skip: !view.filter.on_departments.length && !view.filter.on_templates.length },
  );

  const {
    data: talliesData,
    isLoading: isLoadingTallies,
    isFetching: isFetchingTallies,
  } = useGetTalliesQuery(
    {
      departmentIds: view?.filter?.on_departments ?? [],
      templateList: view?.filter?.on_templates ?? [],
    },
    {
      skip: !view.filter.on_templates.length || selectedTabIndex === ViewEditorAssignmentsTalliesTabs.ASSIGNMENTS,
    },
  );

  const structureData = React.useMemo(() => {
    const structures: AssignmentStructure[] = [];
    const selectedTemplates = view.filter.on_templates;

    assignmentsData?.forEach((assignment) => {
      const assignmentStructures = getAssignmentStructures(assignment, selectedTemplates);

      structures.push(...assignmentStructures);
    });

    return structures;
  }, [assignmentsData, view.filter.on_templates]);

  const getTopBar = (): React.JSX.Element => {
    return (
      <Tabs
        index={selectedTabIndex}
        isLazy
        onChange={(index) => setSelectedTabIndex(index)}
        position={'relative'}
        variant={'unstyled'}
      >
        <TabList>
          <Tab _selected={selectedTabStyle}>Assignments</Tab>
          <Tab _selected={selectedTabStyle}>Tallies</Tab>
          <Tab _selected={selectedTabStyle}>Demand Tallies</Tab>
        </TabList>
        <TabIndicator
          bg={'blue.500'}
          borderRadius={'10px'}
          height={'40px'}
          mt={'-40px'}
          position={'inherit'}
          textColor={'white'}
          zIndex={-1}
        />
      </Tabs>
    );
  };

  const getTabContent = (): React.JSX.Element => {
    switch (selectedTabIndex) {
      case ViewEditorAssignmentsTalliesTabs.TALLIES:
        return (
          <ViewEditorTallies
            isTalliesDataLoading={isLoadingTallies || isFetchingTallies}
            talliesData={talliesData?.tallies ?? []}
          />
        );

      case ViewEditorAssignmentsTalliesTabs.DEMAND:
        return (
          <ViewEditorDemandTallies
            isTalliesDataLoading={isLoadingTallies || isFetchingTallies}
            talliesData={talliesData?.demandTallies ?? []}
          />
        );

      case ViewEditorAssignmentsTalliesTabs.ASSIGNMENTS:
      default:
        return <ViewEditorAssignments assignmentsData={structureData ?? []} isAssignmentDataLoading={isLoading} />;
    }
  };

  // ToDo: Handle no assignments found
  return (
    <VStack gap={5} align={'top'}>
      {getTopBar()}
      {getTabContent()}
    </VStack>
  );
};

export default ViewEditorAssignmentsTallies;
