import { HttpMethods } from 'msw';

import { apiSlice } from '@/API/api.slice';
import { Endpoints } from '@/constants/api';
import DemandTally from '@/types/demandTally.types';
import Tally from '@/types/tally.types';

interface TalliesResponse {
  demand_tallies: DemandTally[];
  tallies: Tally[];
}

interface TallyParams {
  departmentIds: number[];
  templateList: number[];
}

export interface TalliesCollection {
  demandTallies: DemandTally[];
  tallies: Tally[];
}

export const talliesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTallies: builder.query<TalliesCollection, TallyParams>({
      query: (params) => ({
        body: {
          // eslint-disable-next-line camelcase
          department_ids: params.departmentIds.join('~'),
          // eslint-disable-next-line camelcase
          template_list: params.templateList.join('~'),
        },
        method: HttpMethods.POST,
        url: `${Endpoints.Tally}`,
      }),
      transformResponse: (response: TalliesResponse) => {
        const talliesCollection: TalliesCollection = { demandTallies: [], tallies: [] };

        talliesCollection.demandTallies = response.demand_tallies.map((demandTally) => {
          return {
            ...demandTally,
            id: demandTally.tally_id,
            name: demandTally.tally_name,
          };
        });

        talliesCollection.tallies = response.tallies.map((tally) => {
          return {
            ...tally,
            id: tally.tally_id,
            name: tally.tally_name,
          };
        });

        return talliesCollection;
      },
    }),
  }),
});

export const { useGetTalliesQuery } = talliesApi;
