import { Box, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingFn,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import classNames from 'classnames';
import React from 'react';

import {
  DEFAULT_TABLE_HEIGHT_OFFSET,
  DEFAULT_TABLE_HEIGHT_VH,
  DEFAULT_TABLE_SIZE,
  paginationDefault,
} from '@/constants/defaults';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';
import { PaginationFilter } from '@/types/api.types';
import { HeaderSortHandlers } from '@/types/ui.types';
import SortingUtils from '@/utils/sorting';

import PaginationControls from '../pagination-controls/PaginationControls';
import styles from './styles.module.scss';

export interface PaginatedTableControlledProps {
  /** Definition for column structure. React-Table docs recommends <any,any> */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<any, any>[];
  /** A collection of objects with the same structure which represent the table row data */
  data: object[];
  /** A collection of FilterState objects which specify filter state for columns by column id */
  filters: ColumnFiltersState;
  /** Are the data this table relies on still loading */
  isLoading: boolean;
  /** A pagination handler which accepts the current page number and can perform an action when going to a page */
  onGoToPage: (pageNumber: number) => void;
  /** A pagination handler which accepts the current page number and can perform an action when going to the next page */
  onNextPage: (pageNumber: number) => void;
  /** A pagination handler which accepts the current page number and can perform an action when going to the previous page */
  onPreviousPage: (pageNumber: number) => void;
  /** A pagination handler which accepts the new page size and can perform an action when changing the table page size */
  onSetPageSize: (pageSize: number) => void;
  /** A pagination object which contains the current page index, page size, and total pages. This will override the pagination state for React-Table */
  pagination: PaginationFilter;

  hideColumnHeaders?: boolean;
  /** True if this table component is being used by either of the View Access Drawer Tables */
  isPersonnelAccess?: boolean;
  onSortingChange?: OnChangeFn<SortingState>;
  /** A callback function which is passed the cell information. Useful for performing actions reliant on table state */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellClickHandler?: (cell: Cell<any, any>) => void;
  /** A state object which informs React-Table which columns are visible */
  columnVisibility?: VisibilityState;
  /** A collection of callbacks keyed by column id, fired when a sortable header is clicked */
  headerSortHandlers?: HeaderSortHandlers;
  /** Allows us to manually specify sorting state to React-Table; using this will cause React-Table to no longer track sorting state */
  manualSorting?: boolean;
  /** A message to display when no data is found */
  noDataFoundMessage?: string;
  /** Removes Chakra-UI <Td> padding from table rows */
  noRowPadding?: boolean;
  /** Allows us to override the pagination model for React-Table. Use this if your table uses fake pagination. e.g. all the data are sent to the table at once */
  overridePaginationModel?: boolean;
  /** Allows us to specify whether the pagination model is zero-based or one-based. This is useful for when the API uses a different pagination model than React-Table */
  paginationIsZeroBased?: boolean;
  /** Extends and permits the use of custom sorting functions for React-Table; must be defined, but does not have to be used */
  sortingFns?: Record<string, SortingFn<unknown>>;
  /** Allows us to specify sorting state to React-Table; this will override React-Table sorting state and should be used with the manualSorting property */
  sortingState?: ColumnSort[];
  /** A component which will be rendered above the table header, inside the table container */
  TableHeader?: React.ReactNode;
  /** A component which will be rendered in the table header */
  TableHeaderButton?: React.ReactNode;
  /** A ref to the React-Table instance */
  tableInstanceRef?: React.MutableRefObject<unknown>;
  /** A unique row key for React-Table */
  uniqueRowKey?: string;
  /** A unique selected id for React-Table */
  uniqueSelectedIds?: Set<number>;
  tableHeight?: string;
}

const PaginatedTableControlled = (props: PaginatedTableControlledProps): React.JSX.Element => {
  const {
    cellClickHandler,
    columns,
    data,
    filters,
    headerSortHandlers,
    hideColumnHeaders = false,
    isPersonnelAccess = false,
    manualSorting = false,
    onSortingChange,
    noRowPadding = false,
    overridePaginationModel = false,
    pagination = paginationDefault,
    paginationIsZeroBased = false,
    sortingFns = {},
    sortingState,
    tableHeight = DEFAULT_TABLE_HEIGHT_VH,
    TableHeader = null,
    TableHeaderButton = null,
  } = props;

  const tableInstance = useReactTable({
    columns,
    data,
    enableSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: overridePaginationModel ? undefined : getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting,
    onSortingChange,
    sortingFns,
    state: {
      columnFilters: filters,
      columnVisibility: props.columnVisibility,
      pagination: {
        pageIndex: pagination.PageIndex,
        pageSize: pagination.PageSize,
      },
      sorting: sortingState,
    },
  });

  const handleNextPage = () => {
    // eslint-disable-next-line no-magic-numbers
    props.onNextPage && props.onNextPage(pagination.PageNumber + 1);
  };

  const handlePreviousPage = () => {
    // eslint-disable-next-line no-magic-numbers
    props.onPreviousPage && props.onPreviousPage(pagination.PageNumber - 1);
  };

  const canNextPage = () => {
    // eslint-disable-next-line no-magic-numbers
    return pagination?.PageIndex < pagination.TotalPages - 1;
  };
  // eslint-disable-next-line no-magic-numbers
  const canPreviousPage = () => pagination?.PageIndex - 1 >= 0;

  return (
    <HStack gap={0} width="100%" alignItems="flex-start">
      <Box borderWidth="1px" borderRadius="lg" p={2} width="100%">
        <Box className={styles.container}>
          {TableHeader}

          <Box mt={4} p={1}>
            <PaginationControls
              canNextPage={canNextPage()}
              canPreviousPage={canPreviousPage()}
              goToPage={props.onGoToPage}
              isZeroBased={paginationIsZeroBased}
              nextPage={handleNextPage}
              pageCount={pagination.TotalPages}
              pageIndex={pagination.PageIndex}
              previousPage={handlePreviousPage}
              setPageSize={props.onSetPageSize}
              pageSize={pagination.PageSize}
            />
          </Box>
        </Box>

        <TableContainer
          minH={`${DEFAULT_TABLE_SIZE + DEFAULT_TABLE_HEIGHT_OFFSET}px`}
          maxH={tableHeight}
          overflowY={'auto'}
          className={styles.table}
        >
          <Table variant="striped" data-testid={ELEMENT_DATA_TEST_IDS.PAGINATED_TABLE_CONTROLLED}>
            <Thead>
              {tableInstance.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} w={'100%'} display={TableHeaderButton ? 'table' : 'table-row'}>
                  {headerGroup.headers.map((header) => {
                    const headerSortDirection = tableInstance.getState().sorting.find((s) => s.id === header.id)?.desc;
                    const headerSortHandler = headerSortHandlers?.find(
                      (h) => h.columnId === header.column.id,
                    )?.sortHandler;

                    const canSort = header.column.getCanSort();
                    const nextSorting = header.column.getNextSortingOrder();
                    const isSorted = header.column.getIsSorted();

                    return (
                      <Th key={header.id}>
                        {!hideColumnHeaders && (
                          <HStack
                            h={'32px'}
                            maxW={`${DEFAULT_TABLE_SIZE}px`}
                            style={{ cursor: canSort ? 'pointer' : 'unset' }}
                            onClick={headerSortHandler}
                            title={SortingUtils.getSortingTitle(canSort, nextSorting)}
                            data-testid={`paginated-table-controlled-header-${header.id}`}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {SortingUtils.getSortingIcon(isSorted, headerSortDirection)}
                          </HStack>
                        )}
                      </Th>
                    );
                  })}
                  {TableHeaderButton}
                </Tr>
              ))}
            </Thead>
            <Tbody overflowY={'auto'} className={classNames({ [styles.body]: isPersonnelAccess })}>
              {tableInstance.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id} className={classNames({ [styles.row]: isPersonnelAccess })} data-testid={ELEMENT_DATA_TEST_IDS.PAGINATED_TABLE_CONTROLLED_ROW}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          {...(noRowPadding && { padding: 0 })}
                          key={cell.id}
                          onClick={() => cellClickHandler && cellClickHandler(cell)}
                          className={classNames(styles.text)}
                          data-testid={`paginated-table-${cell.column.id}-column`}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </HStack>
  );
};

export default PaginatedTableControlled;
