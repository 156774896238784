/* Sort True False Undefined
 *
 * React-Table sorting state only accepts a boolean value for 'desc'.
 * If 'desc' is true, the column is sorted in descending order.
 * If 'desc' is false, the column is sorted in ascending order.
 * If 'desc' is undefined, we remove the sorter from the sorting state and
 * the column is no longer sorted.
 *
 * Since React-Table doesn't handle undefined for a sort value, we need to
 * transform the undefined value to a boolean value.
 *  */

import { ColumnSort, SortDirection, SortingState } from '@tanstack/react-table';
import { produce } from 'immer';
import React from 'react';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

import { DEFAULT_ASC_SORTING_TITLE, DEFAULT_DESC_SORTING_TITLE, DEFAULT_SORTING_TITLE } from '@/constants/defaults';
import { SORTING_ORDER } from '@/constants/ui';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

const sortTFU = (currentValue: boolean | undefined): boolean | undefined => {
  // If undefined, set to true
  if (currentValue === undefined) {
    return true;
  }

  // If true, set to false
  if (currentValue) {
    return false;
  }

  // If false, set to undefined
  return undefined;
};

const getSortingStateById = (sortingState: SortingState, columnId: string): ColumnSort | undefined =>
  sortingState.find((col) => col.id === columnId);

const setSortingStateById = (sortingState: SortingState, columnId: string, desc: boolean | undefined): ColumnSort[] => {
  const currentSortingState = sortingState.filter((col) => col.id !== columnId);

  return produce(currentSortingState, (draft) => {
    if (desc !== undefined) {
      draft.push({ desc, id: columnId });
    }
  });
};

const sortStrings = (a: string, b: string): number => {
  const aLowerCase = a.toLowerCase();
  const bLowerCase = b.toLowerCase();

  if (aLowerCase === bLowerCase) {
    // eslint-disable-next-line no-magic-numbers
    return 0;
  }

  // eslint-disable-next-line no-magic-numbers
  return aLowerCase < bLowerCase ? -1 : 1;
};

// ASC/DESC and NO sorting
const getSorting = (sortingState: SortingState, columnId: string) => {
  const currentSorting = sortingState.find((item) => item.id === columnId)?.desc;

  // If no sorting, set sorting to ascending (desc: false)
  if (currentSorting === undefined) {
    return false;
  }

  // If sorting is descending, set sorting to undefined (remove sorting)
  if (currentSorting) {
    return undefined;
  }

  // If sorting is ascending, set sorting to descending (desc: true)
  return true;
};

const setSorting = (columnId: string, desc: boolean | undefined, callback: (sorting: SortingState) => void): void => {
  // Sorting for only one column at a time
  const sorting: SortingState = desc !== undefined ? [{ desc, id: columnId }] : [];

  callback(sorting);
};

// Returns sorting title
const getSortingTitle = (canSort: boolean, nextSorting: false | SortDirection): string | undefined => {
  if (canSort) {
    if (nextSorting === SORTING_ORDER.ASC) {
      return DEFAULT_ASC_SORTING_TITLE;
    }
    if (nextSorting === SORTING_ORDER.DESC) {
      return DEFAULT_DESC_SORTING_TITLE;
    }
    return DEFAULT_SORTING_TITLE;
  }
  return undefined;
};

// Returns sorting icon
const getSortingIcon = (isSorted: false | SortDirection, headerSortDirection?: boolean) => {
  if (headerSortDirection !== undefined) {
    return (
      {
        asc: <FaSortUp size={20} style={{ marginTop: '10px' }} data-testid={ELEMENT_DATA_TEST_IDS.SORT_ICON_ASC} />,
        desc: <FaSortDown size={20} style={{ marginTop: '-10px' }} data-testid={ELEMENT_DATA_TEST_IDS.SORT_ICON_DESC} />,
      }[isSorted as string] ?? null
    );
  }
};

const SortingUtils = {
  getSorting,
  getSortingIcon,
  getSortingStateById,
  getSortingTitle,
  setSorting,
  setSortingStateById,
  sortStrings,
  sortTFU,
};
export default SortingUtils;
