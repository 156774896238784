import { ColumnFiltersState } from '@tanstack/react-table';
import { produce } from 'immer';

import { DEFAULT_ARRAY_ELEMENT_DELETE_COUNT, DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND } from '@/constants/defaults';

type InverseBooleanColumns = string[] | null;

/**
 * Accepts a column id and current value and uses Immer produce to create new ColumnFilterState
 *
 * @param id {string} The column name
 * @param value {unknown} The current column value
 * @param columnState {ColumnFiltersState} The current table column filter state
 * @param inverseBooleanColumns {InverseBooleanColumns} An array of column id string to be used for inverse boolean logic. Columns with a value
 * of false will have their filter enabled, otherwise, the filter is removed from the ColumnFilterState
 *
 * @returns {ColumnFiltersState}
 * */
export const updateColumnFilter = (
  id: string,
  value: unknown,
  columnState: ColumnFiltersState,
  inverseBooleanColumns?: InverseBooleanColumns,
): ColumnFiltersState => {
  return produce(columnState, (draft) => {
    const filterIdx = draft.findIndex((filter) => filter.id === id);

    // Boolean columns with the value of false indicates the column should be hidden
    // otherwise, the column will have a value of undefined, in which case the filter will be
    // removed from the filter state
    if (inverseBooleanColumns && inverseBooleanColumns?.indexOf(id) !== DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND) {
      if (value) {
        value = false;
      } else value = undefined;
    }

    if (filterIdx === DEFAULT_ARRAY_ELEMENT_INDEX_NOT_FOUND) {
      draft.push({
        id,
        value,
      });
    } else {
      if (!value) {
        draft.splice(filterIdx, DEFAULT_ARRAY_ELEMENT_DELETE_COUNT);
        return;
      }
      draft[filterIdx] = {
        id,
        value,
      };
    }
  });
};
