import { createSlice } from '@reduxjs/toolkit';

import {
  DEFAULT_PERSONNEL_ID,
  DEFAULT_PERSONNEL_VIEW_ACCESS,
  DEFAULT_PERSONNEL_VIEW_MEMBERSHIP,
} from '@/constants/defaults';
import { PersonnelViewMembershipAccessTabs } from '@/constants/enums';
import { PersonnelViewAccessBody, PersonnelViewMembership } from '@/types/personnel.types';

interface PersonnelMembershipDrawerState {
  isDepartmentListOpen: boolean;
  isDirty: boolean;
  isLoading: boolean;
  isPersonnelMembershipDrawerOpen: boolean;
  isTemplateListOpen: boolean;
  personnelData: PersonnelViewMembership;
  personnelViewAccessDraft: PersonnelViewAccessBody;
  personnelViewAccessOriginal: PersonnelViewAccessBody;
  selectedDepartmentIds: number[];
  selectedTabIndex: PersonnelViewMembershipAccessTabs;
  selectedTemplateIds: number[];
  selectedPersonnelId: number;
  showConfirmChangesDialog: boolean;
}

const initialState: PersonnelMembershipDrawerState = {
  isDepartmentListOpen: false,
  isDirty: false,
  isLoading: false,
  isPersonnelMembershipDrawerOpen: false,
  isTemplateListOpen: false,
  personnelData: DEFAULT_PERSONNEL_VIEW_MEMBERSHIP,
  personnelViewAccessDraft: DEFAULT_PERSONNEL_VIEW_ACCESS,
  personnelViewAccessOriginal: DEFAULT_PERSONNEL_VIEW_ACCESS,
  selectedDepartmentIds: [],
  selectedPersonnelId: DEFAULT_PERSONNEL_ID,
  selectedTabIndex: PersonnelViewMembershipAccessTabs.ACCESS,
  selectedTemplateIds: [],
  showConfirmChangesDialog: false,
};

const personnelMembershipDrawerSlice = createSlice({
  initialState,
  name: 'personnelMembershipDrawer',
  reducers: {
    initializePersonnelMembershipDrawer: (state, action) => {
      state.isPersonnelMembershipDrawerOpen = true;
      state.selectedPersonnelId = action.payload;
    },
    initializePersonnelMembershipDrawerDraft: (state, action) => {
      state.personnelViewAccessDraft = action.payload;
      state.personnelViewAccessOriginal = action.payload;
    },
    resetPersonnelMembershipDrawer: (state) => {
      Object.assign(state, initialState);
    },
    setPersonnelMembershipDrawerDepartmentListOpen: (state, action) => {
      state.isDepartmentListOpen = action.payload;
    },
    setPersonnelMembershipDrawerDraftAccess: (state, action) => {
      state.personnelViewAccessDraft.viewIds = action.payload;
    },
    setPersonnelMembershipDrawerDraftMembership: (state, action) => {
      state.personnelViewAccessDraft.filterIds = action.payload;
    },
    setPersonnelMembershipDrawerIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPersonnelMembershipDrawerOpen: (state, action) => {
      state.isPersonnelMembershipDrawerOpen = action.payload;
    },
    setPersonnelMembershipDrawerPersonnelData: (state, action) => {
      state.personnelData = action.payload;
    },
    setPersonnelMembershipDrawerSelectedDepartmentIds: (state, action) => {
      state.selectedDepartmentIds = action.payload;
    },
    setPersonnelMembershipDrawerSelectedPersonnelId: (state, action) => {
      state.selectedPersonnelId = action.payload;
    },
    setPersonnelMembershipDrawerSelectedTabIndex: (state, action) => {
      state.selectedTabIndex = action.payload;
    },
    setPersonnelMembershipDrawerSelectedTemplateIds: (state, action) => {
      state.selectedTemplateIds = action.payload;
    },
    setPersonnelMembershipDrawerShowConfirmChangesDialog: (state, action) => {
      state.showConfirmChangesDialog = action.payload;
    },
    setPersonnelMembershipDrawerTemplateListOpen: (state, action) => {
      state.isTemplateListOpen = action.payload;
    },
  },
});

export const {
  initializePersonnelMembershipDrawer,
  initializePersonnelMembershipDrawerDraft,
  resetPersonnelMembershipDrawer,
  setPersonnelMembershipDrawerDepartmentListOpen,
  setPersonnelMembershipDrawerDraftAccess,
  setPersonnelMembershipDrawerDraftMembership,
  setPersonnelMembershipDrawerIsLoading,
  setPersonnelMembershipDrawerOpen,
  setPersonnelMembershipDrawerPersonnelData,
  setPersonnelMembershipDrawerSelectedDepartmentIds,
  setPersonnelMembershipDrawerSelectedPersonnelId,
  setPersonnelMembershipDrawerSelectedTabIndex,
  setPersonnelMembershipDrawerSelectedTemplateIds,
  setPersonnelMembershipDrawerShowConfirmChangesDialog,
  setPersonnelMembershipDrawerTemplateListOpen,
} = personnelMembershipDrawerSlice.actions;

export default personnelMembershipDrawerSlice.reducer;
