/**
 * Converts a flat string of key-value pairs delimited by a unique token
 * to an object
 *
 * @param {string} delimitedString
 * @param {string} stringDelimiter
 * @param {string} kvDelimiter
 * @returns {Record<string, string>}
 *
 * @example
 * convertDelimitedKVStringToObject(cookie_one=1;cookie_two=2)
 *
 * @returns
 * \{
 *   cookie_one:1,
 *   cookie_two:2,
 * \}
 */
export const convertDelimitedKVStringToObject = (
  delimitedString: string,
  stringDelimiter: string,
  kvDelimiter: string,
): Record<string, string> => {
  return delimitedString.split(stringDelimiter).reduce((acc, cur) => {
    const kv = cur.split(kvDelimiter);

    // eslint-disable-next-line no-magic-numbers
    return { ...acc, [kv[0]]: kv[1] };
  }, {});
};

/** Wrapper for parseInt(). Accepts a string value and attempts to parse as an int. If the parse result is NaN, the string value is returned*/
export const tryParseInt = (value: string): number | string => {
  const parseResult = parseInt(value);

  return isNaN(parseResult) ? value : parseResult;
};
