import { ViewLayoutType } from '@/types/view.types';

export const isGroupByAssignmentsLayout = (layout: ViewLayoutType): boolean => {
  const validLayouts = [ViewLayoutType.STANDARD, ViewLayoutType.COLUMNS, ViewLayoutType.CALENDAR, ViewLayoutType.GANTT];

  return validLayouts.includes(layout);
};

export const isDemandTallyId = (id: string): boolean => {
  return isNaN(Number.parseInt(id));
};
