import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Spacer,
  Stack,
  Switch,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import ControlledSearch from '@/components/controlled-search/ControlledSearch';
import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import PersonnelMembershipDrawer from '@/components/personnel-membership-drawer/PersonnelMembershipDrawer';
import UIConfig from '@/config/ui.config';
import { ROUTES } from '@/constants/config';
import { DEFAULT_HEADER_Z_INDEX } from '@/constants/defaults';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  setPersonnelPageHideInactiveToggled,
  setPersonnelPageIsDepartmentListOpen,
  setPersonnelPageSearchFilterValue,
  setPersonnelPageSearchValue,
  setPersonnelPageSelectedDepartmentIds,
} from '@/store/slices/personnelPage.slice';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';
import PersonnelListView from '@/views/personnel-list/PersonnelList.view';

const PersonnelPage = (): React.JSX.Element => {
  const { departments } = useAppSelector((state) => state.departmentsAndTemplates);
  const { isHideInactiveToggled, personnelSearchValue, selectedDepartmentIds } = useAppSelector(
    (state) => state.personnelPage,
  );

  const { isPersonnelMembershipDrawerOpen } = useAppSelector((state) => state.personnelMembershipDrawer);

  const dispatch = useAppDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = React.useCallback(
    _.debounce(
      (value: string) => dispatch(setPersonnelPageSearchFilterValue(value)),
      UIConfig.DEFAULT_DEBOUNCE_TIME_MS,
    ),
    [],
  );

  const handlePersonnelSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;

    debouncedSearch(value);
    dispatch(setPersonnelPageSearchValue(value));
  };

  return (
    <>
      <Box pos={'absolute'} zIndex={100}>
        {isPersonnelMembershipDrawerOpen && <PersonnelMembershipDrawer />}
      </Box>
      <Box margin={'0 auto'} data-testid={ELEMENT_DATA_TEST_IDS.PERSONNEL_PAGE}>
        <Stack>
          <Flex mb={2}>
            <Text fontSize="3xl" as="b">
              Personnel
            </Text>
            <Spacer />
            <Stack direction={'row'} marginTop={'auto'} alignItems={'center'} spacing={2}>
              <Box>
                <Link to={ROUTES.VIEWS}>
                  <Button data-testid={ELEMENT_DATA_TEST_IDS.VIEWS_PAGE_BUTTON} size={'md'} leftIcon={<FaUser />} colorScheme={'blue'}>
                    Views List
                  </Button>
                </Link>
              </Box>
            </Stack>
          </Flex>

          <Wrap mt={'auto'} mb={1} zIndex={DEFAULT_HEADER_Z_INDEX} align={'flex-end'}>
            <WrapItem>
              <Stack direction={'row'} align={'center'} spacing={6}>
                <DepartmentsDropdown
                  departmentChangeHandler={(selectedDepartmentIds: number[]) =>
                    dispatch(setPersonnelPageSelectedDepartmentIds(selectedDepartmentIds))
                  }
                  departmentList={departments}
                  departmentListCloseHandler={() => dispatch(setPersonnelPageIsDepartmentListOpen(false))}
                  departmentListOpenHandler={() => dispatch(setPersonnelPageIsDepartmentListOpen(true))}
                  isLoading={false}
                  selectedIds={selectedDepartmentIds}
                />
                <Box mt={8}>
                  <FormControl display="flex" alignItems="center">
                    <HStack justifyContent={'space-between'}>
                      <Switch
                        id="hide-inactive-personnel"
                        isChecked={isHideInactiveToggled}
                        onChange={() => dispatch(setPersonnelPageHideInactiveToggled(!isHideInactiveToggled))}
                      />
                      <FormLabel htmlFor="hide-inactive-personnel" mb="0">
                        Hide Inactive Personnel
                      </FormLabel>
                    </HStack>
                  </FormControl>
                </Box>
              </Stack>
            </WrapItem>
            <Spacer />
            <WrapItem>
              <ControlledSearch
                onChange={handlePersonnelSearch}
                placeholder={'Search Personnel'}
                value={personnelSearchValue}
              />
            </WrapItem>
          </Wrap>

          <PersonnelListView />
        </Stack>
      </Box>
    </>
  );
};

export default PersonnelPage;
