import { Button, HStack, Th } from '@chakra-ui/react';
import React from 'react';

import {
  DEFAULT_DESELECT_ALL,
  DEFAULT_HIGHLIGHT_ALL,
  DEFAULT_SELECT_ALL,
  DEFAULT_UNHIGHLIGHT_ALL,
} from '@/constants/defaults';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

interface TableHeaderSelectAllProps {
  handleSelectAll: () => void;
  handleHighlightAll?: () => void;
  highlightAllIsToggled?: boolean;
  invertSelectAll?: boolean;
  isDisabled?: boolean;
  selectAllIsToggled?: boolean;
  showHighlightAll?: boolean;
}

const FULL_WIDTH = '100%';

const getSelectAllText = (invertSelectAll: boolean, selectAllIsToggled: boolean): string => {
  if (!invertSelectAll) {
    return selectAllIsToggled ? DEFAULT_DESELECT_ALL : DEFAULT_SELECT_ALL;
  }

  return selectAllIsToggled ? DEFAULT_SELECT_ALL : DEFAULT_DESELECT_ALL;
};

const TableHeaderSelectAll = (props: TableHeaderSelectAllProps): React.JSX.Element => {
  const {
    handleHighlightAll,
    handleSelectAll = () => void {},
    highlightAllIsToggled = false,
    invertSelectAll = false,
    isDisabled = false,
    selectAllIsToggled = false,
    showHighlightAll,
  } = props;

  return (
    <Th w={FULL_WIDTH}>
      <HStack w={FULL_WIDTH} justifyContent={'flex-end'}>
        <Button
          data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_SELECT_ALL_BUTTON}
          size="sm"
          onClick={handleSelectAll}
          isDisabled={isDisabled}
        >
          {getSelectAllText(invertSelectAll, selectAllIsToggled)}
        </Button>
        {showHighlightAll && (
          <Button
            data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_HIGHLIGHT_ALL_BUTTON}
            size="sm"
            onClick={handleHighlightAll}
            isDisabled={isDisabled}
          >
            {highlightAllIsToggled ? DEFAULT_UNHIGHLIGHT_ALL : DEFAULT_HIGHLIGHT_ALL}
          </Button>
        )}
      </HStack>
    </Th>
  );
};

export default TableHeaderSelectAll;
