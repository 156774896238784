import { ViewFilter, ViewFilterDTO } from '@/types/view.types';
import { omitKeys } from '@/utils/transformers/omitKeys';

export const serializeViewFilter = (viewFilter: ViewFilter): ViewFilterDTO => {
  const result = omitKeys({ ...viewFilter }, ['created_at', 'created_by', 'on_demandTallies', 'updated_at']);

  // eslint-disable-next-line camelcase
  result.on_tallies = [...viewFilter.on_demandTallies, ...result.on_tallies];

  return result;
};
