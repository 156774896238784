import { Button, Flex, HStack, Select, Spacer, Text } from '@chakra-ui/react';
import React from 'react';
import { FaBackward, FaFastBackward, FaFastForward, FaForward } from 'react-icons/fa';

import UIConfig from '@/config/ui.config';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

interface PaginationControlsProps {
  canNextPage: boolean;
  canPreviousPage: boolean;
  goToPage: (pageIndex: number) => void;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  isZeroBased?: boolean;
  width?: string;
}

const PaginationControls = (props: PaginationControlsProps): JSX.Element => {
  const {
    canNextPage,
    canPreviousPage,
    goToPage,
    isZeroBased = false,
    nextPage,
    pageCount,
    pageIndex,
    pageSize,
    previousPage,
    setPageSize,
    width = '560px',
  } = props;

  // eslint-disable-next-line no-magic-numbers
  const startIndex = isZeroBased ? 0 : 1;
  // eslint-disable-next-line no-magic-numbers
  const endIndex = isZeroBased ? pageCount - 1 : pageCount;

  return (
    <Flex className="pagination" w={width} alignItems={'center'} marginBottom={'8px'} justifyContent={'space-between'}>
      <Flex gap={'1'}>
        <Button
          onClick={() => goToPage(startIndex)}
          disabled={!canPreviousPage}
          size={'xs'}
          data-testid={ELEMENT_DATA_TEST_IDS.FIRST_PAGE_BUTTON}
        >
          <FaFastBackward />
        </Button>
        <Spacer />
        <Button
          data-testid={ELEMENT_DATA_TEST_IDS.PREV_PAGE_BUTTON}
          onClick={() => canPreviousPage && previousPage()}
          isDisabled={!canPreviousPage}
          size={'xs'}
        >
          <FaBackward />
        </Button>
        <Spacer />
        <Button
          data-testid={ELEMENT_DATA_TEST_IDS.NEXT_PAGE_BUTTON}
          onClick={() => canNextPage && nextPage()}
          isDisabled={!canNextPage}
          size={'xs'}
        >
          <FaForward />
        </Button>
        <Spacer />
        <Button
          onClick={() => goToPage(endIndex)}
          disabled={!canNextPage}
          size={'xs'}
          data-testid={ELEMENT_DATA_TEST_IDS.LAST_PAGE_BUTTON}
        >
          <FaFastForward />
        </Button>
      </Flex>
      <Spacer />
      <HStack w={'fit-content'}>
        <Text fontSize={'sm'}>Page:</Text>
        <Text fontSize={'sm'} fontWeight={'bold'} data-testid={ELEMENT_DATA_TEST_IDS.PAGINATION_PAGE_COUNT}>
          {/* eslint-disable-next-line no-magic-numbers */}
          {pageCount === 0 ? `- of -` : `${pageIndex + 1} of ${pageCount}`}
        </Text>
      </HStack>
      <Spacer />
      <Spacer />
      <Select
        data-testid={ELEMENT_DATA_TEST_IDS.PAGE_SIZE_SELECT}
        variant={'outline'}
        onChange={(event) => setPageSize(Number(event.target.value))}
        value={pageSize}
        size={'sm'}
        w={'fit-content'}
        h={'24px'}
      >
        {UIConfig.PAGINATION_PAGE_SIZE_OPTIONS.map(({ value: pageSize, testingId }) => (
          <option
            key={pageSize}
            value={pageSize}
            data-testid={testingId}
          >
            Show {pageSize}
          </option>
        ))}
      </Select>
    </Flex>
  );
};

export default PaginationControls;
