import {
  Box,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Spinner,
  Tab,
  TabList,
  Tabs,
  useToast,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

import {
  useGetPersonnelViewMembershipAndAccessQuery,
  useUpdatePersonnelViewMembershipAndAccessMutation,
} from '@/API/personnel.api';
import ConfirmationDialog from '@/components/confirmation-dialog/ConfirmationDialog';
import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import PersonnelViewAccess from '@/components/personnel-membership-drawer/PersonnelViewAccess';
import PersonnelViewMembership from '@/components/personnel-membership-drawer/PersonnelViewMembership';
import TemplatesDropdown from '@/components/templates-dropdown/TemplatesDropdown';
import {
  DEFAULT_PERSONNEL_ID,
  DEFAULT_PERSONNEL_VIEW_MEMBERSHIP,
  DEFAULT_TOAST_DURATION,
  DEFAULT_Z_INDEX,
  ToastTypes,
} from '@/constants/defaults';
import { PersonnelViewMembershipAccessTabs } from '@/constants/enums';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { makeSelectTemplatesForSelectedDepartments } from '@/store/slices/departmentsAndTemplates.slice';
import {
  initializePersonnelMembershipDrawerDraft,
  resetPersonnelMembershipDrawer,
  setPersonnelMembershipDrawerDepartmentListOpen,
  setPersonnelMembershipDrawerPersonnelData,
  setPersonnelMembershipDrawerSelectedDepartmentIds,
  setPersonnelMembershipDrawerSelectedTabIndex,
  setPersonnelMembershipDrawerSelectedTemplateIds,
  setPersonnelMembershipDrawerShowConfirmChangesDialog,
  setPersonnelMembershipDrawerTemplateListOpen,
} from '@/store/slices/personnelMembershipDrawer.slice';
import { setViewEditorShowConfirmSaveChanges } from '@/store/slices/viewEditor.slice';
import { RootState } from '@/store/store';
import { ApiError } from '@/types/api.types';
import { PersonnelViewAccessBody } from '@/types/personnel.types';

const selectedTabStyle = {
  background: 'blue.500',
  borderRadius: '10px',
  color: 'white',
};

const PERSONNEL_ACCESS_SUCCESS_MESSAGE = 'Personnel access updated successfully.';
const PERSONNEL_ACCESS_ERROR_MESSAGE = 'Error updating personnel access.';

const PersonnelMembershipDrawer = () => {
  const {
    isDepartmentListOpen,
    isLoading,
    isPersonnelMembershipDrawerOpen: isOpen,
    isTemplateListOpen,
    personnelData: { name },
    personnelViewAccessDraft,
    personnelViewAccessOriginal,
    selectedDepartmentIds,
    selectedPersonnelId: personnelId,
    selectedTabIndex,
    selectedTemplateIds,
    showConfirmChangesDialog,
  } = useAppSelector((state) => state.personnelMembershipDrawer);

  const { departments } = useAppSelector((state) => state.departmentsAndTemplates);

  const templateForSelectedDepartmentsSelector = React.useMemo(() => makeSelectTemplatesForSelectedDepartments(), []);

  const selectTemplatesForSelectedDepartments = useAppSelector((state: RootState) =>
    templateForSelectedDepartmentsSelector(state, selectedDepartmentIds),
  );

  const dispatch = useAppDispatch();
  const toast = useToast();

  const { data: personnelViewMembershipData } = useGetPersonnelViewMembershipAndAccessQuery(personnelId, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data, isLoading, isFetching, isError, status }) => ({
      data: data ?? DEFAULT_PERSONNEL_VIEW_MEMBERSHIP,
      isError,
      isFetching,
      isLoading,
      status,
    }),
    skip: !personnelId || personnelId === DEFAULT_PERSONNEL_ID || !isOpen,
  });

  const [updatePersonnelViewAccess] = useUpdatePersonnelViewMembershipAndAccessMutation();

  React.useEffect(() => {
    if (personnelViewMembershipData) {
      const personnelViewAccessDraftData: PersonnelViewAccessBody = {
        filterIds: personnelViewMembershipData.memberOfFilters,
        id: personnelViewMembershipData.id,
        viewIds: personnelViewMembershipData.accessibleViews,
      };

      dispatch(initializePersonnelMembershipDrawerDraft(personnelViewAccessDraftData));
      dispatch(setPersonnelMembershipDrawerPersonnelData(personnelViewMembershipData));
    }
  }, [dispatch, personnelViewMembershipData]);

  const prevSelectedDeptIds = React.useRef<number[]>(selectedDepartmentIds);
  const prevSelectedTemplateIds = React.useRef<number[]>(selectedTemplateIds);

  React.useEffect(() => {
    if (!isDepartmentListOpen) {
      prevSelectedDeptIds.current = selectedDepartmentIds;
    }
  }, [isDepartmentListOpen, selectedDepartmentIds]);

  React.useEffect(() => {
    if (!isTemplateListOpen) {
      prevSelectedTemplateIds.current = selectedTemplateIds;
    }
  }, [isTemplateListOpen, selectedTemplateIds]);

  if (!isOpen) {
    return null;
  }

  const getTopBar = (): React.JSX.Element => {
    return (
      <VStack zIndex={DEFAULT_Z_INDEX} align={'left'} justifyContent={'space-between'} gap={5} mb={1}>
        <Tabs
          variant={'unstyled'}
          position={'relative'}
          index={selectedTabIndex}
          onChange={(index) => dispatch(setPersonnelMembershipDrawerSelectedTabIndex(index))}
          isLazy
        >
          <TabList>
            <Tab _selected={selectedTabStyle}>View Access</Tab>
            <Tab _selected={selectedTabStyle}>View Membership</Tab>
          </TabList>
        </Tabs>
        <HStack spacing="24px" alignItems="flex-end">
          <DepartmentsDropdown
            departmentChangeHandler={(departmentIds: number[]) =>
              dispatch(setPersonnelMembershipDrawerSelectedDepartmentIds(departmentIds))
            }
            departmentList={departments}
            selectedIds={selectedDepartmentIds}
            departmentListCloseHandler={() => dispatch(setPersonnelMembershipDrawerDepartmentListOpen(false))}
            departmentListOpenHandler={() => dispatch(setPersonnelMembershipDrawerDepartmentListOpen(true))}
            isLoading={isLoading}
          />
          <TemplatesDropdown
            selectedIds={selectedTemplateIds}
            templateChangeHandler={(templateIds: number[]) =>
              dispatch(setPersonnelMembershipDrawerSelectedTemplateIds(templateIds))
            }
            templateList={selectTemplatesForSelectedDepartments}
            templateListCloseHandler={() => dispatch(setPersonnelMembershipDrawerTemplateListOpen(false))}
            templateListOpenHandler={() => dispatch(setPersonnelMembershipDrawerTemplateListOpen(true))}
          />
        </HStack>
      </VStack>
    );
  };

  // const accessHasChanged = JSON.stringify(currentPersonnelViewAccess) !== JSON.stringify(personnelViewAccessDraft);

  const handleUpdateEmployeeAccess = async () => {
    // Nothing changed - short circuit
    // if (!accessHasChanged) return;

    try {
      const personnelAccessResponse = await updatePersonnelViewAccess(personnelViewAccessDraft).unwrap();

      if (personnelAccessResponse?.updated) {
        toast({
          description: 'Personnel access updated successfully',
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: PERSONNEL_ACCESS_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? PERSONNEL_ACCESS_ERROR_MESSAGE;

      toast({
        description: 'Error updating personnel access - Network Error',
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    } finally {
      // Close the drawer
      dispatch(resetPersonnelMembershipDrawer());
    }
  };

  const getDrawerBody = (): React.JSX.Element => {
    switch (selectedTabIndex) {
      case PersonnelViewMembershipAccessTabs.ACCESS:
        return <PersonnelViewAccess />;
      case PersonnelViewMembershipAccessTabs.MEMBERSHIP:
        return <PersonnelViewMembership />;
    }
  };

  const isDirty = () => {
    return !_.isEqual(personnelViewAccessOriginal, personnelViewAccessDraft);
  };

  const handleClose = (bypassConfirm = false) => {
    if (isDirty() && !bypassConfirm) {
      dispatch(setPersonnelMembershipDrawerShowConfirmChangesDialog(true));
    } else {
      dispatch(resetPersonnelMembershipDrawer());
    }
  };

  const handleCloseWithoutSaving = () => {
    dispatch(setPersonnelMembershipDrawerShowConfirmChangesDialog(false));
    dispatch(resetPersonnelMembershipDrawer());
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement={'right'}
      onClose={handleClose}
      preserveScrollBarGap={false}
      blockScrollOnMount={true}
      returnFocusOnClose={false}
    >
      <DrawerOverlay />
      <ConfirmationDialog
        closeButtonLabel="Close"
        closeConfirmDialog={() => dispatch(setViewEditorShowConfirmSaveChanges(false))}
        confirmButtonLabel="Save"
        description="Are you sure you want to close without saving?"
        handleClose={handleCloseWithoutSaving}
        handleConfirm={handleUpdateEmployeeAccess}
        isOpen={showConfirmChangesDialog}
        title="Confirm Close"
      />
      <DrawerContent maxW={'1300px'}>
        <Box overflowY={'auto'}>
          <DrawerCloseButton />
          <DrawerHeader>{`Editing view ${_.lowerCase(PersonnelViewMembershipAccessTabs[selectedTabIndex])} for ${
            name.display
          }`}</DrawerHeader>
          <DrawerBody overflow={'unset'}>
            <VStack align={'top'}>
              {getTopBar()}
              {isLoading ? <Spinner>Loading</Spinner> : getDrawerBody()}
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <ButtonGroup>
              <Button colorScheme={'blue'} onClick={handleUpdateEmployeeAccess}>
                Save
              </Button>
              <Button colorScheme={'blue'} variant={'ghost'} onClick={() => handleClose()}>
                Exit
              </Button>
            </ButtonGroup>
          </DrawerFooter>
        </Box>
      </DrawerContent>
    </Drawer>
  );
};

export default PersonnelMembershipDrawer;
