/* eslint-disable no-magic-numbers */
import { array, object, string } from 'yup';

import UIConfig from '@/config/ui.config';

const DEPARTMENTS_ERROR_MESSAGE = 'Please select at least one department.';
const TEMPLATES_ERROR_MESSAGE = 'Please select at least one template.';
const VIEW_NAME_NOT_BLANK = 'View name cannot be blank.';
const VIEW_NAME_MAX_LENGTH_ERROR_MESSAGE = 'View name must not exceed 200 characters.';
const VIEW_NAME_MIN_LENGTH_ERROR_MESSAGE = 'View name must be at least three characters, excluding spaces.';

const viewEditorBasicPropertiesSchema = object({
  departments: array().min(1, DEPARTMENTS_ERROR_MESSAGE).required(),
  templates: array().min(1, TEMPLATES_ERROR_MESSAGE).required(),
  viewName: string()
    .trim()
    .required(VIEW_NAME_NOT_BLANK)
    .min(UIConfig.MINIMUM_VIEW_NAME_LENGTH, VIEW_NAME_MIN_LENGTH_ERROR_MESSAGE)
    .max(UIConfig.MAXIMUM_VIEW_NAME_LENGTH, VIEW_NAME_MAX_LENGTH_ERROR_MESSAGE),
});

export default viewEditorBasicPropertiesSchema;
