import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spacer,
  Stack,
  Text,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { FaFileAlt, FaPlus, FaServer, FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useDeleteViewMutation, useGetPublicViewsQuery, useGetViewQuery } from '@/API/views.api';
import ActionDialog from '@/components/action-dialog/ActionDialog';
import ControlledSearch from '@/components/controlled-search/ControlledSearch';
import DepartmentsDropdown from '@/components/departments-dropdown/DepartmentsDropdown';
import TemplatesDropdown from '@/components/templates-dropdown/TemplatesDropdown';
import ViewAccessDrawer from '@/components/view-access-drawer/ViewAccessDrawer';
import ViewEditorDrawer from '@/components/view-editor-drawer/ViewEditorDrawer';
import UIConfig from '@/config/ui.config';
import { ROUTES } from '@/constants/config';
import { DEFAULT_HEADER_Z_INDEX, DEFAULT_TOAST_DURATION, ToastTypes, VIEW_DELETED_ERROR_MESSAGE, VIEW_DELETED_SUCCESS_MESSAGE } from '@/constants/defaults';
import { ViewEditorDrawerMode } from '@/constants/enums';
import CloneViewModal from '@/modals/clone-view-modal/CloneView.modal';
import ConfirmActionModal from '@/modals/confirm-action-modal/ConfirmAction.modal';
import RequestViewAccessModal from '@/modals/request-view-access-modal/RequestViewAccessModal';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { makeSelectTemplatesForSelectedDepartments } from '@/store/slices/departmentsAndTemplates.slice';
import { setViewAccessPublicViewURL } from '@/store/slices/viewAccess.slice';
import {
  initializeViewEditor,
  initializeViewEditorDraft,
  setViewEditorIsLoading,
  setViewEditorIsOpen,
  setViewEditorMode,
  viewEditorClose,
} from '@/store/slices/viewEditor.slice';
import {
  setViewPageCloneViewModalOpen,
  setViewPageDeleteViewModalOpen,
  setViewPageDepartmentDropdownOpen,
  setViewPageIsActionDialogOpen,
  setViewPagePublicViewsData,
  setViewPageSelectedDepartmentIds,
  setViewPageSelectedTemplateIds,
  setViewPageSelectedViewData,
  setViewPageSelectedViewId,
  setViewPageTemplateDropdownOpen,
  setViewPageViewAccessDrawerOpen,
  setViewPageViewNameSearchFilter,
  setViewPageViewNameSearchValue,
  viewPageResetSelectedView,
} from '@/store/slices/viewPage.slice';
import { RootState } from '@/store/store';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';
import { ApiError } from '@/types/api.types';
import { getLBPublicViewUrl, openViewInNewTab } from '@/utils/url';
import ViewsListView from '@/views/view-list/ViewsList.view';

const ViewsPage = (): React.JSX.Element => {
  const {
    isActionDialogOpen,
    isCloneViewModalOpen,
    isDeleteViewModalOpen,
    isDepartmentDropdownOpen,
    isRequestViewAccessModalOpen,
    isTemplateDropdownOpen,
    isViewAccessDrawerOpen,
    publicViewsData,
    selectedDepartmentIds,
    selectedTemplateIds,
    selectedViewData,
    selectedViewId,
    viewNameSearchFilter,
    viewNameSearchValue,
  } = useAppSelector((state) => state.viewPage);

  const prevSelectedDeptIds = React.useRef<number[]>(selectedDepartmentIds);
  const prevSelectedTemplateIds = React.useRef<number[]>(selectedTemplateIds);

  const {
    isOpen: isViewEditorDrawerOpen,
    isLoading: isViewEditorDrawerLoading,
    mode: viewEditorMode,
  } = useAppSelector((state) => state.viewEditor.uiState);

  const dispatch = useAppDispatch();

  const [deleteView, deleteViewResponse] = useDeleteViewMutation();
  const {
    data: publicViewsQueryData,
    isLoading: publicViewsIsLoading,
    isFetching: publicViewsIsFetching,
  } = useGetPublicViewsQuery();

  const { departments } = useAppSelector((state) => state.departmentsAndTemplates);

  const templateForSelectedDepartmentsSelector = React.useMemo(() => makeSelectTemplatesForSelectedDepartments(), []);

  const selectTemplatesForSelectedDepartments = useAppSelector((state: RootState) =>
    templateForSelectedDepartmentsSelector(state, selectedDepartmentIds),
  );
  
  const {
    data: viewData,
    isFetching: getViewFetching,
    isLoading: getViewLoading,
  } = useGetViewQuery(selectedViewId, { skip: !selectedViewId });

  const toast = useToast();

  React.useEffect(() => {
    if (publicViewsQueryData) {
      dispatch(setViewPagePublicViewsData(publicViewsQueryData));
    }
  }, [dispatch, publicViewsQueryData]);

  React.useEffect(() => {
    if (!isDepartmentDropdownOpen) {
      prevSelectedDeptIds.current = selectedDepartmentIds;
    }
  }, [isDepartmentDropdownOpen, selectedDepartmentIds]);

  React.useEffect(() => {
    if (!isTemplateDropdownOpen) {
      prevSelectedTemplateIds.current = selectedTemplateIds;
    }
  }, [isTemplateDropdownOpen, selectedTemplateIds]);

  const publicViewId = React.useMemo(() => {
    return publicViewsData?.find((view) => view.viewId === selectedViewId)?.guid ?? '';
  }, [publicViewsData, selectedViewId]);

  React.useEffect(() => {
    if (!viewData || !publicViewsData || !selectedViewId || selectedViewId !== viewData?.viewId) return;

    dispatch(setViewPageSelectedViewData({ ...viewData, publicViewId }));
  }, [dispatch, publicViewsData, selectedViewId, viewData, publicViewId]);

  // Since RTK Query caches the view data response and is triggered by the selectedViewId,
  // we need to set the selected view data to the cached response when the selectedViewId changes from NaN to a valid id
  // if the selectedViewId is not the same as the cached response, then RTK Query will fetch the new data which will
  // prevent this from being triggered
  React.useEffect(() => {
    if (!getViewLoading && !getViewFetching && viewData && selectedViewId) {
      dispatch(setViewPageSelectedViewData({ ...viewData, publicViewId }));

      if (isViewEditorDrawerLoading) {
        dispatch(initializeViewEditorDraft(viewData));
        dispatch(setViewEditorIsLoading(false));
        dispatch(setViewEditorIsOpen(true));
      }
    }
  }, [
    dispatch,
    getViewFetching,
    getViewLoading,
    isViewEditorDrawerLoading,
    isViewEditorDrawerOpen,
    viewData,
    publicViewId,
    selectedViewId,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = React.useCallback(
    _.debounce((value: string) => dispatch(setViewPageViewNameSearchFilter(value)), UIConfig.DEFAULT_DEBOUNCE_TIME_MS),
    [],
  );

  const handleSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setViewPageViewNameSearchValue(e.target.value));
      debouncedSearch(e.target.value);
    },
    [debouncedSearch, dispatch],
  );

  const getLoadingViewModal = React.useCallback((): React.JSX.Element => {
    return (
      <Modal isOpen={getViewLoading || getViewFetching} onClose={() => undefined} returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent data-testid={`${ELEMENT_DATA_TEST_IDS.LOADING_VIEW_DIALOG}`}>
          <ModalHeader textAlign={'center'}>Loading View Data</ModalHeader>
          <ModalBody>
            <HStack w={'100%'} h={'80px'} justifyContent={'space-between'}>
              <FaServer size={'30px'} />
              <Progress size="xs" isIndeterminate w={'90%'} />
              <FaFileAlt size={'30px'} />
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }, [getViewFetching, getViewLoading]);

  // Open view edit drawer on edit mode from Clone View Dialog and close the dialog after click
  const handleOpenView = React.useCallback(() => {
    dispatch(setViewEditorIsOpen(true));
  }, [dispatch]);

  const isDataLoading = publicViewsIsLoading || publicViewsIsFetching || getViewLoading || getViewFetching;

  const cloneViewModal = React.useMemo(() => {
    if (getViewLoading || getViewFetching) return getLoadingViewModal();

    if (!selectedViewData) return <></>;

    return (
      <CloneViewModal
        isLoading={isDataLoading}
        isOpen={isCloneViewModalOpen}
        openView={handleOpenView}
        onClose={() => {
          dispatch(setViewPageCloneViewModalOpen(false));
          dispatch(viewPageResetSelectedView());
        }}
        view={selectedViewData}
      />
    );
  }, [
    dispatch,
    getLoadingViewModal,
    getViewFetching,
    getViewLoading,
    handleOpenView,
    isCloneViewModalOpen,
    isDataLoading,
    selectedViewData,
  ]);

  const handleCancelDeleteView = React.useCallback(() => {
    dispatch(setViewPageDeleteViewModalOpen(false));
    dispatch(viewPageResetSelectedView());
  }, [dispatch]);

  const handleDeleteView = React.useCallback(async () => {
    try {
      const response = await deleteView(selectedViewId).unwrap();

      // Check if the view was deleted successfully
      if (response?.deleted) {
        toast({
          duration: DEFAULT_TOAST_DURATION,
          isClosable: true,
          position: 'top',
          status: ToastTypes.SUCCESS,
          title: VIEW_DELETED_SUCCESS_MESSAGE,
        });
      }
    } catch (error) {
      // Cast unknown error type to ApiError
      const apiError = error as ApiError;
      const message = apiError?.data?.Message ?? VIEW_DELETED_ERROR_MESSAGE;

      toast({
        duration: DEFAULT_TOAST_DURATION,
        isClosable: true,
        position: 'top',
        status: ToastTypes.ERROR,
        title: message,
      });
    } finally {
      handleCancelDeleteView();
    }
  }, [deleteView, handleCancelDeleteView, selectedViewId, toast]);

  const getConfirmDeleteViewModalBody = React.useMemo(() => {
    return (
      <VStack>
        <Text align={'center'} data-testid={ELEMENT_DATA_TEST_IDS.CONFIRM_DELETE_VIEW_NAME}>
          This will <span color={'red'}>DELETE</span> the <span color={'blue'}>{selectedViewData?.name}</span> view.
        </Text>
        <br></br>
        <Text>Are you sure you want to delete this view?</Text>
      </VStack>
    );
  }, [selectedViewData?.name]);

  const confirmDeleteViewModal = React.useMemo(() => {
    if (getViewLoading || getViewFetching) return getLoadingViewModal();

    return (
      <ConfirmActionModal
        body={getConfirmDeleteViewModalBody}
        confirmAction={handleDeleteView}
        header={'Confirm Delete View'}
        cancelAction={handleCancelDeleteView}
        confirmActionButtonText={'Delete'}
        isLoading={deleteViewResponse?.isLoading}
        isOpen={isDeleteViewModalOpen}
      />
    );
  }, [
    getViewLoading,
    getViewFetching,
    getLoadingViewModal,
    getConfirmDeleteViewModalBody,
    handleDeleteView,
    handleCancelDeleteView,
    deleteViewResponse?.isLoading,
    isDeleteViewModalOpen,
  ]);

  // Close editor drawer but preserve the mode, open the action dialog and retrieve view's data
  const handleSaveView = React.useCallback(
    (viewId: number) => {
      dispatch(setViewPageSelectedViewId(viewId));
      dispatch(setViewEditorIsOpen(false));
      dispatch(setViewPageIsActionDialogOpen(true));
    },
    [dispatch],
  );

  const handleViewEditorDrawerClose = React.useCallback(() => {
    dispatch(viewEditorClose());
    dispatch(viewPageResetSelectedView());
  }, [dispatch]);

  const handleViewAccessDrawerClose = React.useCallback(() => {
    dispatch(viewPageResetSelectedView());
  }, [dispatch]);

  const getViewAccessDrawer = React.useMemo(() => {
    if (getViewLoading || getViewFetching) return getLoadingViewModal();
    if (!isViewAccessDrawerOpen || !selectedViewData || !Object.keys(selectedViewData).length) return <></>;

    return <ViewAccessDrawer onClose={handleViewAccessDrawerClose} />;
  }, [
    getLoadingViewModal,
    getViewFetching,
    getViewLoading,
    handleViewAccessDrawerClose,
    isViewAccessDrawerOpen,
    selectedViewData,
  ]);

  const getViewEditorDrawer = React.useMemo(() => {
    if (getViewLoading || getViewFetching) return getLoadingViewModal();

    if (!isViewEditorDrawerOpen) return <></>;

    return <ViewEditorDrawer onClose={handleViewEditorDrawerClose} onSave={handleSaveView} />;
  }, [
    getLoadingViewModal,
    getViewFetching,
    getViewLoading,
    handleSaveView,
    handleViewEditorDrawerClose,
    isViewEditorDrawerOpen,
  ]);

  const handleCreateView = React.useCallback(() => {
    dispatch(setViewEditorMode(ViewEditorDrawerMode.CREATE));
    dispatch(setViewEditorIsOpen(true));
  }, [dispatch]);

  // Open view access drawer from Action Dialog and close the dialog after click
  const handleDialogAccessDrawer = React.useCallback(() => {
    dispatch(setViewAccessPublicViewURL(getLBPublicViewUrl(publicViewId)));
    dispatch(setViewPageViewAccessDrawerOpen(true));
    dispatch(setViewPageIsActionDialogOpen(false));
    dispatch(viewEditorClose());
  }, [dispatch, publicViewId]);

  // Just close the dialog, the Views List data is already retrieved
  const handleActionDialogClose = React.useCallback(() => {
    dispatch(setViewPageIsActionDialogOpen(false));
    // reset the state for the next create dialog open
    handleViewEditorDrawerClose();
  }, [dispatch, handleViewEditorDrawerClose]);

  // Open view edit drawer on create mode from Action Dialog and close the dialog after click
  const handleDialogCreateView = React.useCallback(() => {
    handleActionDialogClose();
    dispatch(setViewEditorMode(ViewEditorDrawerMode.CREATE));
    dispatch(setViewEditorIsOpen(true));
  }, [dispatch, handleActionDialogClose]);

  // Open view edit drawer on edit mode from Action Dialog and close the dialog after click
  const handleDialogEditView = React.useCallback(() => {
    dispatch(setViewPageIsActionDialogOpen(false));
    dispatch(
      initializeViewEditor({
        isLoading: true,
        mode: ViewEditorDrawerMode.EDIT,
      }),
    );
    dispatch(setViewEditorIsOpen(true));
  }, [dispatch]);

  // Open the view in viewer app and close the dialog after click
  const handleDialogOpenViewer = React.useCallback(() => {
    if (selectedViewId) {
      openViewInNewTab(selectedViewId);
    }

    dispatch(setViewPageIsActionDialogOpen(false));
  }, [selectedViewId, dispatch]);

  // Action Dialog after successful view save (edit/create)
  const getActionDialog = React.useMemo(() => {
    if (selectedViewData) {
      return (
        <ActionDialog
          handleClose={handleActionDialogClose}
          handleCreateView={handleDialogCreateView}
          handleEditView={handleDialogEditView}
          handleOpenViewer={handleDialogOpenViewer}
          handleViewAccess={handleDialogAccessDrawer}
          handleViewsList={handleActionDialogClose}
          isOpen={isActionDialogOpen}
          mode={viewEditorMode}
          viewName={selectedViewData?.name || ''}
        />
      );
    }

    return <></>;
  }, [handleDialogAccessDrawer, handleDialogCreateView, handleDialogEditView, handleDialogOpenViewer, handleActionDialogClose, isActionDialogOpen, selectedViewData, viewEditorMode]);

  return (
    <div data-testid={ELEMENT_DATA_TEST_IDS.VIEWS_PAGE}>
      {getActionDialog}
      <Box pos={'absolute'} zIndex={100}>
        {isRequestViewAccessModalOpen && <RequestViewAccessModal />}
        {cloneViewModal}
        {confirmDeleteViewModal}
        {getViewAccessDrawer}
        {getViewEditorDrawer}
      </Box>
      <Box>
        <Stack>
          <Flex mb={2}>
            <Text fontSize="3xl" as="b">
              Views
            </Text>
            <Spacer />
            <Stack direction={'row'} marginTop={'auto'} align={'center'} spacing={2}>
              <Box>
                <ButtonGroup>
                  <Button size={'md'} leftIcon={<FaPlus />} colorScheme={'blue'} onClick={handleCreateView} data-testid={ELEMENT_DATA_TEST_IDS.CREATE_VIEW_BUTTON}>
                    Create View
                  </Button>
                  <Link to={ROUTES.PERSONNEL}>
                    <Button data-testid={ELEMENT_DATA_TEST_IDS.PERSONNEL_PAGE_BUTTON} size={'md'} leftIcon={<FaUser />} colorScheme={'blue'}>
                      Personnel
                    </Button>
                  </Link>
                </ButtonGroup>
              </Box>
            </Stack>
          </Flex>

          <Wrap mt={'auto'} mb={1} zIndex={DEFAULT_HEADER_Z_INDEX} align={'flex-end'}>
            <WrapItem>
              <Stack direction={'row'} maxW={'800px'} minW={'520px'} align={'center'} spacing={2}>
                <DepartmentsDropdown
                  departmentChangeHandler={(departmentIds) => dispatch(setViewPageSelectedDepartmentIds(departmentIds))}
                  departmentList={departments}
                  departmentListCloseHandler={() => dispatch(setViewPageDepartmentDropdownOpen(false))}
                  departmentListOpenHandler={() => dispatch(setViewPageDepartmentDropdownOpen(true))}
                  isLoading={false}
                  selectedIds={selectedDepartmentIds}
                />
                <TemplatesDropdown
                  isLoading={false}
                  selectedIds={selectedTemplateIds}
                  templateChangeHandler={(templateIds) => dispatch(setViewPageSelectedTemplateIds(templateIds))}
                  templateList={selectTemplatesForSelectedDepartments}
                  templateListCloseHandler={() => dispatch(setViewPageTemplateDropdownOpen(false))}
                  templateListOpenHandler={() => dispatch(setViewPageTemplateDropdownOpen(true))}
                />
              </Stack>
            </WrapItem>
            <Spacer />
            <WrapItem>
              <ControlledSearch onChange={handleSearch} placeholder={'Search View Name'} value={viewNameSearchValue} />
            </WrapItem>
          </Wrap>

          <ViewsListView
            isLoading={false}
            selectedDepartmentIds={isDepartmentDropdownOpen ? prevSelectedDeptIds.current : selectedDepartmentIds}
            selectedTemplateIds={isTemplateDropdownOpen ? prevSelectedTemplateIds.current : selectedTemplateIds}
            setSelectedViewId={(id) => dispatch(setViewPageSelectedViewId(id))}
            toggleViewAccessDrawer={() => dispatch(setViewPageViewAccessDrawerOpen(true))}
            toggleConfirmCloneModal={() => dispatch(setViewPageCloneViewModalOpen(true))}
            toggleConfirmDeleteModal={() => dispatch(setViewPageDeleteViewModalOpen(true))}
            toggleViewEditorDrawer={() => {
              dispatch(
                initializeViewEditor({
                  isLoading: true,
                  mode: ViewEditorDrawerMode.EDIT,
                }),
              );
            }}
            viewNameFilter={viewNameSearchFilter}
          />
        </Stack>
      </Box>
    </div>
  );
};

export default ViewsPage;
