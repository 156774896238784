import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import SearchInput from '@/components/search-input/SearchInput';
import { ELEMENT_DATA_TEST_IDS } from '@/tests/testConstants';

interface TableHeaderProps {
  label: string;
  placeholder: string;
  handleFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TableHeader = (props: TableHeaderProps): React.JSX.Element => {
  const { handleFilter = () => void {}, label = '', placeholder = '' } = props;

  return (
    <Box>
      <VStack justifyContent="flex-start" alignItems="flex-start" gap={3} p={1}>
        <Text color={'black'} fontWeight={500}>
          {label}
        </Text>
        <HStack gap={2} justifyContent="space-between" width="100%">
          <SearchInput
            data-testid={ELEMENT_DATA_TEST_IDS.TABLE_HEADER_SEARCH}
            onChange={handleFilter}
            placeholder={placeholder}
            variant={'outline'}
            size="sm"
            width="100%"
          />
        </HStack>
      </VStack>
    </Box>
  );
};

export default TableHeader;
